import {
  useCalculatedQuestions,
  useQuestionnaire,
  useQuestions,
  useScore
} from 'api/saq/hooks/queries';
import {
  QScore,
  Questionnaire,
  QuestionnaireType,
  Section,
  SectionResult,
  SubCategory
} from 'api/saq/types';
import {
  extractScoreByQuestion,
  mapAnswersWithScores,
  mapCalcQuestions
} from './utils';

export const useGetQuestionScoresData = (
  category: SubCategory,
  questionnaireType: QuestionnaireType
) => {
  const questionTypeToSkip = ['WRAPPER'];

  const {
    data: questionnaireData,
    isLoading: isQuestionnaireLoading
  } = useQuestionnaire(questionnaireType);

  const {
    data: calculatedQuestionsData,
    isLoading: areCalcQuestionsLoading
  } = useCalculatedQuestions(questionnaireType);

  const { data: scoreData, isLoading: isScoreLoading } = useScore(
    questionnaireData?.id,
    questionnaireType
  );

  const { data: questionData, isLoading: isQuestionLoading } = useQuestions(
    questionnaireData?.id
  );

  const scores = scoreData?.results;
  const allQuestions = [
    ...(mapCalcQuestions(calculatedQuestionsData?.results) || []),
    ...(questionData?.results || [])
  ];

  function extractRelatedQuestions(section: SectionResult) {
    return allQuestions
      ?.filter(
        (questionResult) =>
          questionResult?.tags?.find((tag) => tag.category === 'RISK')
            ?.subCategory === category
      )
      .filter(
        (questionResult) =>
          !questionTypeToSkip.includes(questionResult.questionType)
      )
      .filter((questionResult) => questionResult.subsectionId === section.id)
      .map((questionResult) => {
        const scoreResult = extractScoreByQuestion(questionResult.id, scores);
        return {
          id: questionResult.id,
          label: questionResult.label,
          maxScore: scoreResult?.scores.maxScore || '',
          subsection: section.name,
          scoreId: scoreResult?.id,
          scoreType: scoreResult?.scores.type,
          tags:
            questionResult?.tags?.filter((tag) => tag.category === 'PILLAR') ||
            [],
          answers:
            mapAnswersWithScores(questionResult, scoreResult?.scores) || []
        } as QScore;
      });
  }

  const employeeAndWorkerSection = {
    id: 'employee',
    code: 'employee',
    label: 'Workers Info',
    questions: extractRelatedQuestions({
      id: 'employee',
      name: 'Employee'
    } as SectionResult)
  } as Section<QScore>;

  const saqSections =
    questionnaireData?.sections?.map((sectionResult: SectionResult) => {
      return {
        id: sectionResult.id,
        code: sectionResult.code,
        label: sectionResult.name,
        questions: sectionResult.children.flatMap((subsection) =>
          extractRelatedQuestions(subsection)
        )
      };
    }) || [];

  const questionnaire = {
    id: questionnaireData?.id,
    type: questionnaireType,
    sections: [employeeAndWorkerSection, ...saqSections]
  } as Questionnaire<QScore>;

  return {
    questionnaire,
    isLoading:
      isScoreLoading ||
      isQuestionLoading ||
      isQuestionnaireLoading ||
      areCalcQuestionsLoading
  };
};
