import {
  Answer,
  AScore,
  Band,
  BandAScore,
  BasicAnswer,
  BasicAScore,
  CalculatedQuestionResult,
  CompanySizeScore,
  QuestionResult,
  Score,
  ScorePossibleAnswer,
  ScoreResult,
  UpdateAnswerScore
} from '../../types';

const companySizeKeys = [
  'MICRO',
  'SMALL',
  'MEDIUM',
  'LARGE'
] as (keyof CompanySizeScore)[];

const emptyCompanySizeScores = companySizeKeys.map((key) => {
  return { label: key } as Score;
});

export function extractScoreByQuestion(
  questionCode: string,
  scores?: ScoreResult[]
) {
  return scores?.find((score) => score.questionCode === questionCode);
}

function extractCompanySizeScores(
  answerCode: string,
  scores?: ScorePossibleAnswer[]
) {
  const score = scores?.find((score) => score.code === answerCode);
  return score?.scores;
}

export function mapAnswersWithScores(
  questionResult?: QuestionResult,
  score?: UpdateAnswerScore
) {
  switch (score?.type) {
    case 'choice':
      return mapChoiceTypeScores(
        questionResult?.possibleAnswers,
        score.possibleAnswers
      );
    case 'banded':
      return mapBandedTypeScores(score.bands);
    case 'basic':
      return mapBasicTypeScores(score.basicAnswer);
    default:
      return mapAnswer(questionResult?.possibleAnswers);
  }
}

function mapBasicTypeScores(basic?: BasicAnswer) {
  return (
    basic &&
    Object.keys(basic).map((answerKey) => {
      return {
        id: answerKey,
        label: answerKey,
        type: answerKey,
        scores:
          companySizeKeys.map((key) => {
            return {
              label: key,
              value: basic?.[answerKey as keyof BasicAnswer]?.[key]
            } as Score;
          }) || emptyCompanySizeScores
      } as BasicAScore;
    })
  );
}

function mapAnswer(possibleAnswers?: Answer[]) {
  if (possibleAnswers === undefined) {
    return [
      {
        id: 'not implemented',
        label: 'not implemented',
        scores: emptyCompanySizeScores
      } as AScore
    ];
  }
  return possibleAnswers?.map((answer) => {
    return {
      id: answer.id,
      label: answer.label,
      scores: emptyCompanySizeScores
    } as AScore;
  });
}

function mapChoiceTypeScores(
  answers?: Answer[],
  scores?: ScorePossibleAnswer[]
) {
  return answers?.map((answer) => {
    const companySizeScores = extractCompanySizeScores(answer.id, scores);
    return {
      ...answer,
      scores:
        companySizeKeys.map((key) => {
          return {
            label: key,
            value: companySizeScores?.[key]
          } as Score;
        }) || emptyCompanySizeScores
    } as AScore;
  });
}

function mapBandedTypeScores(bands?: Band[] | undefined) {
  return bands?.map((band) => {
    const companySizeScores = band.scores;
    return {
      id: `from-${band.from ? band.from : 0}${band.to ? `-to-${band.to}` : ''}`,
      label: `from ${band.from ? band.from : 0}${
        band.to ? ` to ${band.to}` : ''
      }`,
      ...band,
      scores:
        companySizeKeys.map((key) => {
          return {
            label: key,
            value: companySizeScores?.[key]
          } as Score;
        }) || emptyCompanySizeScores
    } as BandAScore;
  });
}

export function mapCalcQuestions(calcQuestions?: CalculatedQuestionResult[]) {
  return calcQuestions?.map((question) => {
    return {
      ...question,
      possibleAnswers: question.options?.map((option) => {
        return {
          id: option.key,
          label: option.label
        };
      })
    } as QuestionResult;
  });
}
