import Environment from 'Environment';

import AxiosTransportLayer, { TransportLayer } from './TransportLayer';
import AuthStore from '../services/auth/AuthStore';

const authStore = new AuthStore();

export const userService: TransportLayer<any> = new AxiosTransportLayer(
  Environment.userServiceBaseUrl,
  authStore
);

export const questionnaireService: TransportLayer<any> = new AxiosTransportLayer(
  Environment.questionnaireBaseUrl,
  authStore
);

export const scoringService: TransportLayer<any> = new AxiosTransportLayer(
  Environment.scoringBaseUrl,
  authStore
);

export const questionnaireWorkplaceEtlService: TransportLayer<any> = new AxiosTransportLayer(
  Environment.questionnaireWorkplaceEtlBaseUrl,
  authStore
);

export const workplaceService: TransportLayer<any> = new AxiosTransportLayer(
  Environment.workplaceBaseUrl,
  authStore
);
