import { ColumnMeta, QScore, QuestionnaireType, Section } from 'api/saq/types';
import { useQuestionnaireContext } from '../context/QuestionnaireProvider';
import { QuestionnaireTypeSelector } from '../../components/QuestionnaireTypeSelector';
import React from 'react';
import { commonSaqColumnNamesArray } from '../consts/commonSaqColumnNames';
import { useGetQuestionScoresData } from '../../../api/saq/hooks/useQuestionScoresData/useGetQuestionScores';
import { ScoresTable } from '../../../components/ScoresTable/ScoresTable';
import { Layout } from '../../../layout/Layout';
import { MCSectionContainer } from '../SaqRisksTable.styles';
import { Spinner } from '../../../atoms/spinner/Spinner';

const managementControlsColumns = [
  { name: 'Sub-section', property: 'SUBSECTION_NAME' },
  { name: 'Question', property: 'QUESTION' }
] as ColumnMeta[];

export const ManagementControls = () => {
  const { questionnaireType } = useQuestionnaireContext();
  const { questionnaire, isLoading } = useGetQuestionScoresData(
    'MANAGEMENT_CONTROLS',
    questionnaireType as QuestionnaireType
  );

  const managementControlColumnNames = managementControlsColumns
    .map((col) => col.name)
    .concat(commonSaqColumnNamesArray);

  return (
    <>
      <QuestionnaireTypeSelector />
      {isLoading ? (
        <Spinner isSuspense />
      ) : (
        <Layout title="Management Controls - question scores">
          {questionnaire.sections
            .filter((section) => section.questions.length > 0)
            .map((section: Section<QScore>) => (
              <MCSectionContainer key={`section-${section.id}`}>
                <ScoresTable
                  title={section.label}
                  headerNames={managementControlColumnNames}
                  columnsMeta={managementControlsColumns}
                  sections={[section]}
                />
              </MCSectionContainer>
            ))}
        </Layout>
      )}
    </>
  );
};
