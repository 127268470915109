import {
  Button,
  CircularProgress,
  Grid,
  Paper,
  TextField,
  Typography
} from '@material-ui/core';
import { ChangeEvent, useState } from 'react';
import { useStyles } from '../../Features/features';
import { WorkplaceRiskTable } from 'components/WorkplaceRiskTable/WorkplaceRiskTable';
import { useSiteRisksScore } from 'api/saq/hooks/useSiteRisksScore/useSiteRisksScore';

export const WorkplaceRisk = () => {
  const classes = useStyles();

  const [siteCode, setSiteCode] = useState<string>('');
  const [rawSiteCode, setRawSiteCode] = useState<string>('');

  const {
    siteCharacteristicsRisk,
    managementControlsRisk,
    isLoading,
    isSuccess
  } = useSiteRisksScore(siteCode);

  const onChange = (event: ChangeEvent<HTMLInputElement>) =>
    setRawSiteCode(event.target.value);

  const onSearchHandler = (event: ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (rawSiteCode !== siteCode) {
      setSiteCode(rawSiteCode);
    }
  };

  return (
    <Paper className={classes.paper}>
      <Typography variant="h6">Workplace Risk</Typography>
      <form onSubmit={onSearchHandler}>
        <Grid className={classes.inputForm}>
          <TextField
            label="Site Code"
            onChange={onChange}
            value={rawSiteCode}
          />
          <Button type="submit" className={classes.button} variant="contained">
            Search
          </Button>
        </Grid>
      </form>
      {!isLoading && isSuccess && (
        <div>
          {(siteCharacteristicsRisk.questionScores.length !== 0 ||
            siteCharacteristicsRisk.pillars.length !== 0) && (
            <div>
              <h2>Site Characteristics</h2>
              <WorkplaceRiskTable
                title={'Total score'}
                data={[
                  {
                    label: 'Total score',
                    score: siteCharacteristicsRisk.totalScore
                  }
                ]}
              ></WorkplaceRiskTable>
              <WorkplaceRiskTable
                title={'Pillar'}
                data={siteCharacteristicsRisk.pillars}
              ></WorkplaceRiskTable>
              <WorkplaceRiskTable
                title={'Question Scores'}
                data={siteCharacteristicsRisk.questionScores}
              ></WorkplaceRiskTable>
            </div>
          )}
          {(managementControlsRisk.questionScores.length !== 0 ||
            managementControlsRisk.sectionScores.length !== 0) && (
            <div>
              <h2>Management Controls</h2>
              <WorkplaceRiskTable
                title={'Total score'}
                data={[
                  {
                    label: 'Total score',
                    score: managementControlsRisk.totalScore
                  }
                ]}
              ></WorkplaceRiskTable>
              <WorkplaceRiskTable
                title={'Section Scores'}
                data={managementControlsRisk.sectionScores}
              ></WorkplaceRiskTable>
              <WorkplaceRiskTable
                title={'Question Scores'}
                data={managementControlsRisk.questionScores}
              ></WorkplaceRiskTable>
            </div>
          )}
        </div>
      )}
      {siteCode !== '' && !isLoading && !isSuccess && (
        <div>Invalid siteCode</div>
      )}
      {isLoading && <CircularProgress />}
    </Paper>
  );
};
