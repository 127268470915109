import { QScore, ScoreUpdate } from 'api/saq/types';
import { scoringService } from 'api/services';
import { SaqIdentifiers, ScoringUrls } from 'api/saq/resourcesUrl';
import { useMutation } from 'react-query';
import {
  convertBandsToPayload,
  convertBasicToPayload,
  convertChoiceToPayload
} from './utils';

const updateScores = (payload: ScoreUpdate) =>
  scoringService.put(ScoringUrls.updateScores(payload.scoreId), payload.scores);

export const useUpdateQuestionScores = () => {
  return useMutation(
    SaqIdentifiers.updateScores,
    (questionScore: QScore) => {
      return updateScores({
        scoreId: questionScore.scoreId,
        scores: {
          possibleAnswers: convertChoiceToPayload(questionScore),
          bands: convertBandsToPayload(questionScore),
          basicAnswer: convertBasicToPayload(questionScore),
          maxScore: questionScore.maxScore,
          type: questionScore.scoreType
        }
      } as ScoreUpdate);
    },
    {
      onError: () => {
        alert('Unable to update scores');
      }
    }
  );
};
