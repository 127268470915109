import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { StarBorderTwoTone } from '@material-ui/icons';
import { Features } from 'screens/Features/features';
import { RouteConfig } from './RouteConfig';
import { FeaturesList } from 'screens/Features/list/FeatureList';
import { FeatureDetail } from 'screens/Features/detail/FeatureDetail';
import { AddFeature } from 'screens/Features/add/AddFeature';
import { WorkplaceRiskAnswers } from 'screens/saq/WorkplaceRiskAnswers/WorkplaceRiskAnswers';
import { WorkplaceRisk } from '../screens/saq/WorkplaceRisk/WorkplaceRisk';
import { QuestionnaireProvider } from '../screens/saq/context/QuestionnaireProvider';
import { SiteCharacteristic } from '../screens/saq/SiteCharacteristic/SiteCharacteristic';
import { ManagementControls } from '../screens/saq/ManagementControls/ManagementControls';
import { ThresholdAndOffsets } from '../screens/saq/ThresholdAndOffsets/ThresholdAndOffsets';

export const FeaturesDashboardRoutes: RouteConfig[] = [
  {
    path: '/admin/features',
    sidebarName: 'Features',
    component: FeaturesList,
    icon: StarBorderTwoTone
  },
  {
    path: '/admin/userfeatures',
    sidebarName: 'User Features',
    component: Features,
    icon: StarBorderTwoTone
  }
];

const FeaturesRoutes: RouteConfig[] = [
  {
    path: '/admin/features/detail',
    component: FeatureDetail
  },
  {
    path: '/admin/features/add',
    component: AddFeature
  }
];

export const SaqRoutes: RouteConfig[] = [
  {
    path: '/admin/saq/site/sc',
    sidebarName: 'Site Characteristics',
    component: SiteCharacteristic,
    icon: StarBorderTwoTone
  },
  {
    path: '/admin/saq/site/mc',
    sidebarName: 'Management Controls',
    component: ManagementControls,
    icon: StarBorderTwoTone
  },
  {
    path: '/admin/saq/site/risks-answers',
    sidebarName: 'Site Workplace Risks Answers',
    component: WorkplaceRiskAnswers,
    icon: StarBorderTwoTone
  },
  {
    path: '/admin/saq/site/risks',
    sidebarName: 'Site Workplace Risks',
    component: WorkplaceRisk,
    icon: StarBorderTwoTone
  },
  {
    path: '/admin/saq/site/threshold',
    sidebarName: 'Threshold and offsets',
    component: ThresholdAndOffsets,
    icon: StarBorderTwoTone
  }
];

const AdminRouter: React.FC = () => {
  const routes = FeaturesDashboardRoutes.concat(FeaturesRoutes);

  return (
    <Switch>
      {routes.map((route) => (
        <Route
          exact
          key={route.path}
          path={route.path}
          component={route.component}
        />
      ))}
      <QuestionnaireProvider>
        {SaqRoutes.map((route) => (
          <Route
            exact
            key={route.path}
            path={route.path}
            component={route.component}
          />
        ))}
      </QuestionnaireProvider>
    </Switch>
  );
};

export default AdminRouter;
