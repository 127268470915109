import { ColumnMeta, QuestionnaireType } from 'api/saq/types';
import React from 'react';
import { useQuestionnaireContext } from '../context/QuestionnaireProvider';
import { QuestionnaireTypeSelector } from '../../components/QuestionnaireTypeSelector';
import { commonSaqColumnNamesArray } from '../consts/commonSaqColumnNames';
import { ScoresTable } from '../../../components/ScoresTable/ScoresTable';
import { useGetQuestionScoresData } from '../../../api/saq/hooks/useQuestionScoresData/useGetQuestionScores';
import { Spinner } from '../../../atoms/spinner/Spinner';

const columnsMeta = [
  { name: 'Pillar', property: 'PILLAR' },
  { name: 'Question', property: 'QUESTION' },
  { name: 'SAQ vs Worker info table', property: 'IS_SAQ' }
] as ColumnMeta[];

export const SiteCharacteristic = () => {
  const { questionnaireType } = useQuestionnaireContext();
  const { questionnaire, isLoading } = useGetQuestionScoresData(
    'SITE_CHARACTERISTICS',
    questionnaireType as QuestionnaireType
  );

  const scColumnNames = columnsMeta
    .map((col) => col.name)
    .concat(commonSaqColumnNamesArray);

  return (
    <>
      <QuestionnaireTypeSelector />
      {isLoading ? (
        <Spinner isSuspense />
      ) : (
        <ScoresTable
          title="Site Characteristics - question scores"
          sections={questionnaire.sections}
          headerNames={scColumnNames}
          columnsMeta={columnsMeta}
        />
      )}
    </>
  );
};
