import {
  Band,
  BandAScore,
  BasicAnswer,
  BasicAScore,
  CompanySizeScore,
  QScore,
  ScorePossibleAnswer
} from '../../../types';

export const convertBasicToPayload = (questionScore: QScore) => {
  const result =
    questionScore.scoreType === 'basic' &&
    (questionScore.answers as BasicAScore[]).reduce((prev, current) => {
      prev[current.type] = current.scores.reduce((previous, current) => {
        previous[current.label as keyof CompanySizeScore] = current.value;
        return previous;
      }, {} as CompanySizeScore);
      return prev;
    }, {} as BasicAnswer);

  return result || undefined;
};

export const convertBandsToPayload = (questionScore: QScore) => {
  const result =
    questionScore.scoreType === 'banded' &&
    (questionScore.answers as BandAScore[]).map((band) => {
      return {
        from: band.from,
        to: band.to,
        scores: band.scores.reduce((previous, current) => {
          previous[current.label as keyof CompanySizeScore] = current.value;
          return previous;
        }, {} as CompanySizeScore)
      } as Band;
    });
  return result || undefined;
};

export const convertChoiceToPayload = (questionScore: QScore) => {
  const result =
    questionScore.scoreType === 'choice' &&
    questionScore.answers.map((answer) => {
      return {
        code: answer.id,
        scores: answer.scores.reduce((previous, current) => {
          previous[current.label as keyof CompanySizeScore] = current.value;
          return previous;
        }, {} as CompanySizeScore)
      } as ScorePossibleAnswer;
    });
  return result || undefined;
};
