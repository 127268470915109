export const SaqIdentifiers = {
  questionnaire: 'QUESTIONNAIRE',
  score: 'SCORE',
  updateScores: 'UPDATE_SCORES',
  question: 'QUESTION',
  calculatedQuestions: 'CALCULATED_QUESTIONS',
  calculatedAnswers: 'CALCULATED_ANSWERS',
  siteQuestionnaireStatus: 'SITE_QUESTIONNAIRE_STATUS',
  workplaceRiskScores: 'WORKPLACE_RISK_SCORES',
  answers: 'ANSWERS',
  thresholdAndOffsets: 'THRESHOLD_AND_OFFSETS',
  workplace: 'WORKPLACE'
};

export const SaqUrls = {
  questionnaire: (questionnaireType: string) =>
    `/questionnaire/type/${questionnaireType}`,
  question: (questionnaireId: string) =>
    `/questionnaire/${questionnaireId}/question`,
  answers: (siteQuestionnaireId: string) =>
    `/questionnaire/site-questionnaire/${siteQuestionnaireId}/answers`,
  siteQuestionnaireStatus: (siteCode: string, questionnaireType: string) =>
    `/questionnaire/site-questionnaire/status?siteCode=${siteCode}&questionnaireType=${questionnaireType}`,
  file: (
    siteQuestionnaireId: string,
    sectionId: string,
    questionId: string,
    fileId: string
  ) =>
    `/questionnaire/site-questionnaire/${siteQuestionnaireId}/section/${sectionId}/answers/${questionId}/file/${fileId}`,
  thresholdAndOffsets: () => `/scores/risk-thresholds`
};

export const ScoringUrls = {
  score: (questionnaireType: string) =>
    `/scores/questionnaire/${questionnaireType}`,
  updateScores: (scoreIdParam: string) =>
    `/scores/questionnaire/${scoreIdParam}`,
  workplaceRiskScores: (siteCode: string) => `/workplaces/${siteCode}`
};

export const QuestionnaireWorkplaceEtlUrls = {
  calculatedQuestions: (questionnaireType: string) =>
    `/questions/${questionnaireType.toUpperCase()}/risk`,
  calculatedAnswers: (questionnaireType: string, siteCode: string) =>
    `/answers/${questionnaireType}/${siteCode}`
};

export const Workplace = {
  workplaceProfile: (siteCode: string) => `/all/workplace/code/${siteCode}`
};
