import { AScore, ColumnMeta, QScore } from '../../api/saq/types';
import React, { Fragment, useState } from 'react';
import { EditControls } from './EditControls';
import { EditCell } from './EditCell';
import { AnswerScoreCells } from './AnswerScoreCells';
import { TableCell } from '@mui/material';
import { StyledTableRow } from '../../screens/saq/SaqRisksTable.styles';
import { useUpdateQuestionScores } from '../../api/saq/hooks/mutations';
import { getCategory } from '../../api/saq/utils';

type QuestionRowProps = {
  questionScore: QScore;
  columnsMeta: ColumnMeta[];
};

export const QuestionRow = ({
  questionScore,
  columnsMeta
}: QuestionRowProps) => {
  const { mutateAsync: updateScores } = useUpdateQuestionScores();
  const [rowQuestionState, setRowQuestionState] = useState(questionScore);
  const [isEditable, setIsEditable] = useState(false);

  const showEdit = () => {
    setIsEditable(true);
  };

  const onHideEdit = () => {
    setIsEditable(false);
  };

  const onAnswerChange = (answerScore: AScore) => {
    const index = rowQuestionState.answers.findIndex(
      (answer) => answer.id === answerScore.id
    );

    if (index !== -1) {
      rowQuestionState.answers[index] = answerScore;
      setRowQuestionState({
        ...rowQuestionState
      });
    }
  };

  const onMaxScoreChange = (value: string) => {
    setRowQuestionState({
      ...rowQuestionState,
      maxScore: Number.parseInt(value, 10)
    });
  };

  const onSaveScores = () => {
    onHideEdit();
    updateScores(rowQuestionState);
  };

  const answerScores = rowQuestionState.answers.flatMap(
    (answerScore) => answerScore.scores
  );

  const isMaxScoreInvalid =
    (!rowQuestionState.maxScore && rowQuestionState.maxScore !== 0) ||
    rowQuestionState.maxScore > 100 ||
    answerScores.some((score) => rowQuestionState.maxScore < score.value);

  const answerSize = questionScore.answers.length || 1;
  return (
    <Fragment key={`question-${questionScore.id}`}>
      {/*
      There is no way to view multiple rows grouped for MUI table.
      As a workaround we need to view all the answer scores data as rows and expand question data cells to fit answer rows.
      */}
      {questionScore.answers?.map((answer, index) => (
        <StyledTableRow
          showDelimiter={index === 0}
          key={`row-${questionScore.id}-${index}`}
        >
          {/*question metadata*/}
          {index === 0 &&
            columnsMeta.map((column) => (
              <TableCell
                align="center"
                size="small"
                scope="row"
                rowSpan={answerSize}
                key={`question-${questionScore.id}-${column.property}`}
              >
                {getCategory(questionScore, column.property)}
              </TableCell>
            ))}

          <AnswerScoreCells
            key={`question-${questionScore.id}-answer-${answer.id}-scores`}
            questionCode={questionScore.id}
            answerScore={answer}
            isEditable={isEditable}
            onShow={showEdit}
            onChange={onAnswerChange}
          />

          {index === 0 && (
            <>
              {/*max score editable cell*/}
              <EditCell
                key={`${questionScore.id}-max-score`}
                questionCode={questionScore.id}
                value={questionScore.maxScore}
                isEditable={isEditable}
                onShow={showEdit}
                onChange={onMaxScoreChange}
                isError={isMaxScoreInvalid}
                rowSpan={answerSize}
              />
              <EditControls
                key={`${questionScore.id}-controls`}
                isEditable={isEditable}
                isDisabled={isMaxScoreInvalid}
                onSaveScores={onSaveScores}
                onHideEdit={onHideEdit}
                rowSpan={answerSize}
              />
            </>
          )}
        </StyledTableRow>
      ))}
    </Fragment>
  );
};
